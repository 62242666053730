<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Crear usuario
      </h2>
    </div>
    <b-card>
      <UserForm
        ref="user-form"
        v-model="user"
      />
      <b-col cols="12 text-right">
        <b-button @click="handleSubmit" variant="primary" class="mt-2 mr-1">
          {{ "Enviar" }}
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserForm from '@/components/users/form/UserForm.vue'

export default {
  components: {
    UserForm
  },
  data() {
    return {
      user: {},
      profileFile: null,
      userPrepare: false,
      notifications: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage'
    }),
    userForm() {
      return this.$refs['user-form']
    },
  },
  methods: {
    ...mapActions({
      create: 'users/createnew',
    }),
    async handleSubmit() {
      const success = await this.userForm.validateForm()

      if (!success) {
        this.$refs.errorField.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });

        return
      }

      this.create({ user: this.user, route: this.$route.name })


      // const formData = this.createFormData()

      // if (formData) {
      // }
     
    },
    // createFormData() {
    //   // const data = this.$refs.images.getFormData()
    //   const data = new FormData()

    //   data.append('name', this.name)
    //   data.append('email', this.email)
    //   data.append('surname', this.surname)
    //   data.append('password', this.password)

    //   return data
    // },
  },
}
</script>